<template>
    <div>
        <b-card no-body class="p-2">

                <b-row>
                    <b-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
                        <h1>{{ i18nT(`Transactions`) }}</h1>
                        <div class="mb-1">
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                              {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                              {{ i18nT(`entries`) }}</span>
                        </div>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                        sm="12"
                    >
						<b-dropdown
							v-if="iCan( 'payments', 'write' )"
							class="float-right"
							right
							id="add-new-dropdown"
							variant="primary"
							v-b-tooltip.hover="bankAccounts.length<=1 ? tooltipText : ''"
							:disabled="bankAccounts.length<=1 || totalDocuments == 0"
						>
							<template #button-content>
								<feather-icon icon="RefreshCcwIcon" class="mr-05" />
								{{ i18nT(`Sync transactions`) }}
							</template>

							<b-dropdown-item>
								<div
									@click="onSync"
									v-b-tooltip.hover="i18nT(`Get the latest transactions for connected bank accounts`)"

									:class="{'text-primary': totalDocuments !== 0}"
								>
									<feather-icon icon="RefreshCcwIcon" class="mr-05"/>
									{{ i18nT(`Sync transactions`) }}
								</div>
							</b-dropdown-item>
							<b-dropdown-item
								v-b-modal.modal-export
								:disabled="totalDocuments == 0"
							>
								<div :class="{'text-primary': totalDocuments !== 0}">
									<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
									{{ i18nT(`Export`) }}
								</div>
							</b-dropdown-item>

						</b-dropdown>
<!--                      <div :title="tooltipText">-->
<!--                        <b-button-->
<!--                            variant="primary"-->
<!--                            @click="onSync"-->
<!--                            class="float-right"-->
<!--                            v-if="iCan( 'payments', 'write' )"-->
<!--                            v-b-tooltip.hover="i18nT(`Get the latest transactions for connected bank accounts`)"-->
<!--                            :disabled="bankAccounts.length<=1"-->
<!--                        >-->
<!--                            <feather-icon icon="RefreshCcwIcon" class="mr-05"/>-->
<!--                            {{ i18nT(`Sync transactions`) }}-->
<!--                        </b-button>-->
<!--                      </div>-->
                    </b-col>
                </b-row>

                <!-- FILTERS -->
                <b-row>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group
                            :label="i18nT(`Transaction date range`)"
                            label-for="paymentType"
                        >
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-button
                                        variant="primary"
                                        @click.stop="inputClick"
                                    >
                                        <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                                    </b-button>
                                </b-input-group-prepend>
                                <VueCtkDateTimePicker
                                    :id="'TransactionsDateRange'"
                                    class=""
                                    :range="true"
                                    :max-date="maxDate"
                                    :min-date="minDate"
                                    :disabled="false"
                                    v-model="reportRange"
                                    :only-date="true"
                                    :only-time="false"
                                    :format="`YYYY-MM-DD`"
                                    :formatted="dateFormat"
                                    :color="`#5a5aff`"
                                    :label="i18nT(`Select date`)"
                                    :button-now-translation="i18nT(`Today`)"
                                    :first-day-of-week="1"
                                    :no-button="true"
                                    :auto-close="true"
                                    :locale="locale"
                                    :ref="`transactionRangePicker`"
                                />
                            </b-input-group>

                        </b-form-group>
                    </b-col>



                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Bank Account`)">
                            <v-select
                                v-model="bankAccount"
                                :options="bankAccounts"
                                :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Currency`)">
                            <v-select
                                v-model="currency"
                                :options="currencies"
                                :reduce="(e) => e.value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Match status`)">
                            <v-select
                                v-model="matchedStatus"
                                :options="matchOptions"
                                :reduce="(e) => e.value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- SEARCH -->
                <b-row>
                    <b-col cols="3">
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            v-if="selectedItems.length > 0"
                            class="d-inline ml-1"
                            size="md"
                            text="Actions"
                            variant="outline-secondary"
                        >
                            <b-dropdown-item
                                v-if="iCan( 'bank', 'write' )"
                                @click="onBulkDelete(data)">
                                <feather-icon color="red" icon="TrashIcon"/>
                                <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`) }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col cols="6"></b-col>
                    <b-col cols="3">
                        <div class="w-100 d-flex justify-content-end">
                            <b-form-group>
                                <div
                                    class="input-group input-group-merge"
                                    role="group"
                                >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <feather-icon icon="SearchIcon"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Search`)"
                                        class="d-inline-block"
                                        type="text"
                                    />
                                </div>
                            </b-form-group>



                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-table
                            ref="refDocumentListTable"
                            v-model="currentItems"
                            :empty-html="emptyHtml"
                            :fields="computedColumns"
                            :items="fetchDocuments"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="isSortDirDesc"
                            class="position-relative"
                            primary-key="id"
                            responsive
                            show-empty
                            @refreshed="onTableRefresh"
                        >
                            <template v-slot:head(checkbox)="data">
                                <span>{{ data.label.toUpperCase() }}</span>
                                <b-form-checkbox
                                    v-model="selectAllStatus"
                                    class="d-inline-block pl-0"
                                    @change="onSelectAll"
                                >
                                </b-form-checkbox>
                            </template>

                            <template #head(action)="data">
                                <span>{{ data.label.toUpperCase() }}</span>
                                <div class="sk-column-control">
                                    <b-dropdown
                                        no-caret
                                        variant="button"
                                        toggle-class="p-0"
                                        :right="true"
                                        class="pr-1 pl-1 d-flex sk-column-filter"
                                        v-if="betaFeature"
                                        v-b-tooltip = "i18nT(`Customize columns`)"
                                    >
                                        <template #button-content>
                                            <feather-icon
                                                icon="SettingsIcon"
                                                size="18"
                                                class="text-right"
                                            />
                                        </template>

                                        <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                            <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                        </div>
                                    </b-dropdown>
                                </div>
                            </template>

                            <template #cell(checkbox)="data">
                                <b-form-checkbox
                                    v-model="selectedItems"
                                    :value="data.item.Id"
                                    class="pl-0"
                                />
                            </template>

                            <template #cell(name)="data">
                                <h5>
                                    {{ data.item.Label }}
                                </h5>
                            </template>

                            <template #cell(bank_account)="data">
                                <h5 v-if="data.item.BankAccountObj">{{ data.item.BankAccountObj.Bank }}</h5>
                            </template>

                            <template #cell(amount)="data">
                                <h5 :class="data.item.Amount >= 0 ? `text-success` : `text-danger`">{{ {amount: data.item.Amount, currency: data.item.Currency} | currency }}</h5>
                            </template>

                            <template #cell(currency)="data">
                                <h5>{{ data.item.Currency }}</h5>
                            </template>

                            <template #cell(date)="data">
                                <h5>{{ data.item.TransactionDate | date}}</h5>
                            </template>

                            <template #cell(status)="data">
                                <b-badge
                                    :variant="statusLabelClass(data.item.PaymentId)"
                                    class="mr-1 badge-pill">
                                    {{data.item.PaymentId ? i18nT(`Matched`) : i18nT(`Unmatched`) }}
                                </b-badge>
                            </template>

                            <template #cell(action)="data">
                                <b-dropdown
                                    :right="$store.state.appConfig.isRTL"
                                    no-caret
                                    toggle-class="p-0"
                                    variant="link"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            class="align-middle text-body"
                                            icon="MoreVerticalIcon"
                                            size="16"
                                        />
                                    </template>
                                    <b-dropdown-item @click="onCreateInvoice(data)">
                                        <feather-icon icon="PlusIcon"/>
                                        <span class="align-middle ml-50"
                                        >{{ i18nT(`Add new record in Finances`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="data.item.PaymentId == 0"
                                        @click="onRecordPayment(data)">
                                        <feather-icon icon="LinkIcon"/>
                                        <span class="align-middle ml-50">
                                            {{ i18nT(`Record as payment`) }}
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="data.item.PaymentId !== 0"
                                        @click="resetMatch(data)">
                                        <font-awesome-icon :icon="['fas', 'unlink']" />
                                        <span class="align-middle ml-50">
                                            {{ i18nT(`Reset match`) }}
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="iCan( 'bank', 'write' )"
                                        @click="onDelete(data)">
                                        <feather-icon
                                            color="red"
                                            icon="TrashIcon"
                                        />
                                        <span
                                            class="align-middle ml-50"
                                            style="color: red"
                                        >{{
                                                i18nT(`Delete`)
                                            }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="mx-1 mb-2">
                        <b-col
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                            cols="12"
                            sm="6"
                        >
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block mr-1"
                                @change="onSelectAll"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            cols="12"
                            sm="6"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalDocuments"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>

        </b-card>

        <!-- MODAL -->
        <b-modal
            id="modal-vendor"
            no-close-on-backdrop
            ok-only
            size="lg"
            :title="i18nT(`Vendor Info`)"
        >
            <vendor-view :data="selectedVendor"/>
        </b-modal>

        <transaction-to-finance :transaction="selectedTransaction" @refresh="onRefresh"/>

        <transaction-to-payment :transaction="selectedTransaction" @ready="onRefresh"/>

		<modal-export module="bank_transactions" exportModule="transactions" :allFilters="allFilters"/>


    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    // BAvatar,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTabs,
    // BTab,
    // BListGroup, BListGroupItem,
    // BCardFooter,
    // BCardBody,
    BFormCheckbox,
    // BFormSelect,
    // BLink,
    BFormInput,
    // BCardHeader,
    // BBadge,
    VBTooltip,
    VBModal, BBadge, BButton, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useStatementList from './useStatementList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import useAuth from '@/auth/useAuth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// import _ from 'lodash'

import VendorView from './VendorView.vue'
import TransactionToFinance from "@/views/accounting/Transactions/TransactionToFinance.vue";
import TransactionToPayment from "@/views/accounting/Transactions/TransactionToPayment.vue";
import moment from "moment/moment";
import ModalExport from "@/views/common/ModalExport/ModalExport.vue";

export default {
    components: {
		ModalExport,
        BInputGroupPrepend, BInputGroup, BButton,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BTable,
        // BAvatar,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BTabs,
        // BTab,
        // BListGroup,
        // BListGroupItem,
        // BCardFooter,
        // BCardBody,
        vSelect,
        BFormCheckbox,
        // BFormSelect,
        // BLink,
        BFormInput,
        // BCardHeader,
        // BBadge,
        // VBModal,
        TransactionToFinance,
        TransactionToPayment,
        VendorView,
        VueCtkDateTimePicker
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal
    },
    data() {
        return {
            selectedTransaction: {},
            selectedVendor: {},
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            bankAccounts: [],
            currencies: [],
            isActionsShow: false,
            software: useAuth.getCurrentSoftware(),
            reportRange: null,
            avalableFields: [
                {label: this.i18nT(`Date`), key: 'date', visible: true},
                {label: this.i18nT(`Amount`), key: 'amount', visible: true},
                {label: this.i18nT(`Description`), key: 'name', visible: true},
                {label: this.i18nT(`Bank`), key: 'bank_account', visible: true},
                {label: this.i18nT(`Currency`), key: 'currency', visible: true},
                {label: this.i18nT(`Status`), key: 'status', visible: true},
            ],
            matchOptions: [
                { value: '', text: this.i18nT(`All`)},
                { value: 1, text: this.i18nT(`Matched`)},
                { value: 2, text: this.i18nT(`Unmatched`)},

            ],
            tooltipText: this.i18nT(`Connect a bank account to sync transactions`),
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('BankTransactionTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.bankAccounts.unshift({
            value: '',
            text: this.i18nT(`All`)
        })

        this.currencies.unshift({
            value: '',
            text: this.i18nT(`All`)
        })

        this.$http.get(`transactions/bankAccountsFilter`).then(({data}) => {
            this.bankAccounts.push(...dictToSelectArray(data.data))

            if(this.$route.query.account) {
                this.bankAccount = this.$route.query.account;
            }
        })

        this.$http.get(`transactions/currenciesFilter`).then(({data}) => {
            this.currencies.push(...dictToSelectArray(data.data))
        })

        this.matchedStatus = '';

    },
    methods: {
        resetMatch(data) {
            this.$swal({
                title: this.i18nT(`Are you sure you want to reset this transaction?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, reset it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .post(`transactions/resetMatch?transaction_id=${data.item.Id}`)
                        .then(data => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        }).catch(error => {
                            if (error) {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.errorMessage(error),
                                        icon: 'InfoIcon',
                                        variant: 'danger'
                                    }
                                })
                            }
                        })

                    }
            })
        },
        inputClick() {
            const inputElement = this.$refs[`transactionRangePicker`].$el.querySelector('input');
            if (inputElement) {
                inputElement.click();
            }
        },
        statusLabelClass(status) {
            return status ? 'success' : 'warning';
        },
        onRecordPayment(data){
            this.selectedTransaction = data.item;
            this.$bvModal.show('modal-link-transaction')
        },
        onCreateInvoice(data){
            this.selectedTransaction = data.item;
            this.$bvModal.show('modal-create-invoice')
        },
        getTypeName(typeId) {
            const vendor = this.bankAccounts.find(type => type.value == typeId)
            return vendor ? vendor.text : ''
        },
        onRefresh() {
            this.refetchData()
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onSync() {
            this.$http
                .get(`transactions/importAll`)
                .then(data => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(data),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.refetchData()
                }).catch(error => {
                    if(error){
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(error),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    }else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Something went wrong!`),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    }

                })
        },
        onDelete(transaction) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`transactions?id=${transaction.item.Id}`)
                        .then(data => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                }
            })
        },

        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete them!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`transactions?id=${this.selectedItems.join(',')}`)
                        .then((data) => {
                            this.$swal({
                                icon: 'success',
                                title: this.successMessage(data),
                                text: this.i18nT(`The transactions have been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        },
        popupVendor(vendor) {
            this.selectedVendor = JSON.parse(JSON.stringify(vendor))
            this.selectedVendor.Type = this.getTypeName(this.selectedVendor.Type)
            this.$bvModal.show('modal-vendor')
        }
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            employees,
            bankAccount,
            currency,
            vendors,
            matchedStatus,
            startDate,
            endDate,
            refetchData
        } = useStatementList()

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            avatarText,
            employees,
            bankAccount,
            currency,
            vendors,
            matchedStatus,
            startDate,
            endDate,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('BankTransactionTemplates', this.avalableFields);
            return col
        },
        minDate() {
            return moment('2022-01-01').format();
        },
        maxDate(){
            return moment().format();
        },
        emptyHtml() {
          if(this.bankAccount.length>1){
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`Here, you can create and manage your transactions. No transactions have been created yet.`) + "</h4>"
                +"<button class='btn btn-outline-secondary mt-2'  id='transactionButton'>" + this.i18nT(`Sync transactions`) + "</button>"
          } else {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`Here, you can create and manage your transactions. No transactions have been created yet.`) + "</h4>"
                +"<button class='btn btn-outline-secondary mt-2' disabled  id='transactionButton' title='" + this.tooltipText + "'>" + this.i18nT(`Sync transactions`) + "</button>"
          }

        },
        locale() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
		allFilters(){
			const params = {
				export: 1,
				sort_by: this.sortBy,
				sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
				search: this.searchQuery,
				start_date: this.startDate,
				end_date: this.endDate,
			}

			if (this.bankAccount != null) {
				params['filters[bank_account]'] = this.bankAccount
			}

			if (this.currency != null) {
				params['filters[currency]'] = this.currency
			}
			if (this.matchedStatus != null) {
				params['filters[matched]'] = this.matchedStatus
			}
			if(this.startDate != ''){
				params['filters[start_date]'] = this.startDate;
			}
			if(this.endDate != ''){
				params['filters[end_date]'] = this.endDate;
			}
			return params;
		}
    },
    mounted(){
      if(document.querySelector('#transactionButton')){
        document.querySelector('#transactionButton').addEventListener('click', this.onSync);
      }
    },
    beforeUnmount() {
      document.querySelector('#transactionButton').removeEventListener('click', this.onSync);
    },
    watch: {
        reportRange(newRange) {

            if(newRange) {

                if(newRange.start) {
                    this.startDate = moment(newRange.start).format('YYYY-MM-DD');
                }
                if (newRange.end) {
                    this.endDate = moment(newRange.end).format('YYYY-MM-DD');
                }

            } else {
                this.startDate = '';
                this.endDate = '';
            }

        },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.disabled {
    color: #6c757d;
}

.vs__clear {
    display: none;
}

.sk-column-filter{
    width: 50px;
}
</style>
